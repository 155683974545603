import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Loadable from 'react-loadable';
import { useSelector } from './hooks/useSelector';
import Loading from './components/loading';
import { mainRouters as routers } from './route';
import MainLayout from './layouts/mainLayout';

const HomePage = Loadable({
  loader: () => import('./pages/home'),
  loading: Loading,
});

const NotFindPage = Loadable({
  loader: () => import('./pages/notFind'),
  loading: Loading,
});

const LoginPage = Loadable({
  loader: () => import('./pages/login'),
  loading: Loading,
});

const PrivateRoute = ({ component, ...rest }) => {
  const token = useSelector((s) => s.user.token);
  const routeComponent = (props) => {
    return token ? React.createElement(component, props) : <Redirect to={{ pathname: '/login' }} />;
  };
  return (
    <MainLayout>
      <Route render={routeComponent} {...rest} />
    </MainLayout>
  );
};

function renderRoute() {
  const result = [
    routers.map((item) => {
      if (item.children?.length) {
        return item.children.map((mItem) => {
          return (
            <PrivateRoute
              key={mItem.path}
              path={`/${item.path}/${mItem.path}`}
              component={mItem.component}
            />
          );
        });
      }
      return null;
    }),
    routers.map((item) => {
      if (item.redirect) {
        return (
          <Redirect
            key={item.redirect}
            from={`/${item.path}`}
            to={{ pathname: `/${item.redirect}` }}
          />
        );
      }
      return null;
    }),
  ].flat(Infinity);
  return result;
}

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/login" component={LoginPage} />
        {renderRoute()}
        <Route component={NotFindPage} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
