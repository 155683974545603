import React, { useCallback, useEffect } from 'react';
import { Layout, Menu } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Header from '../../components/header';
import styles from './MainLayout.module.css';
import { menuSlice } from '../../redux/menu/slice';
import { useSelector } from '../../hooks/useSelector';
import { mainRouters } from '../../route';

const { SubMenu } = Menu;
const { Content, Sider } = Layout;

const MainLayout: React.FC = ({ children }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { subMenuKey, menuItemKey } = useSelector((s) => s.menu);

  const handleTitleClick = useCallback(
    (e) => {
      dispatch(
        menuSlice.actions.changeMenu({
          subMenuKey: e.key,
          menuItemKey,
        }),
      );
    },
    [dispatch, menuItemKey],
  );

  const handleMenuClick = useCallback(
    (value) => {
      dispatch(
        menuSlice.actions.changeMenu({
          subMenuKey: value.keyPath[1],
          menuItemKey: value.keyPath[0],
        }),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    const pathNameList = history.location.pathname.split('/');
    dispatch(
      menuSlice.actions.changeMenu({
        subMenuKey: pathNameList[1],
        menuItemKey: pathNameList[2],
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <Header />
      <Layout className={styles.mainLayout}>
        <Sider width={200} className={styles['sider-layout-background']}>
          <Menu
            mode="inline"
            style={{ height: '100%', borderRight: 0, width: 200 }}
            defaultOpenKeys={[subMenuKey]}
            openKeys={[subMenuKey]}
            defaultSelectedKeys={[menuItemKey]}
            selectedKeys={[menuItemKey]}
            onClick={handleMenuClick}
          >
            {mainRouters.map((item) => (
              <SubMenu
                key={item.path}
                icon={item.icon}
                title={item.name}
                onTitleClick={handleTitleClick}
              >
                {item.children?.map((mItem) => (
                  <Menu.Item key={mItem.path}>
                    <Link to={`/${item.path}/${mItem.path}`}>{mItem.name}</Link>
                  </Menu.Item>
                ))}
              </SubMenu>
            ))}
          </Menu>
        </Sider>
        <Layout>
          <Content
            className={styles['content-layout-background']}
            style={{
              padding: 26,
              margin: 0,
              minHeight: 280,
            }}
          >
            <div className={styles['page-content']}>{children}</div>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
