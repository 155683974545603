import React from 'react';
import { Typography, Divider } from 'antd';
import { useDispatch } from 'react-redux';
import styles from './Header.module.css';
import { useSelector } from '../../hooks/useSelector';
import { userSlice } from '../../redux/user/slice';

const Header: React.FC = () => {
  const dispatch = useDispatch();
  const token = useSelector((s) => s.user.token);

  return (
    <div className={styles.header}>
      <Typography.Title level={5} className={styles.title}>
        AI模型数据管理平台
      </Typography.Title>
      <div>
        <Typography.Text className={styles.username}>HI,Siri -- {token}</Typography.Text>
        <Divider type="vertical" className={styles.divider} />
        <Typography.Text className={styles.login}>
          {token ? (
            <span className={styles.login} onClick={() => dispatch(userSlice.actions.logOut())}>
              退出
            </span>
          ) : (
            <span className={styles.login}>登录</span>
          )}
        </Typography.Text>
      </div>
    </div>
  );
};

export default Header;
