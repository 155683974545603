import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { userSlice } from './user/slice';
import { dataManageSlice } from './dataManage/slice';
import { actionLog } from './middlewares/actionLog';
import { menuSlice } from './menu/slice';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user'],
};

const rootReducer = combineReducers({
  user: userSlice.reducer,
  dataManage: dataManageSlice.reducer,
  menu: menuSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    return [...getDefaultMiddleware({ serializableCheck: false }), actionLog];
  },
  devTools: true,
});

const persistor = persistStore(store);

export type RootState = ReturnType<typeof persistedReducer>;

export default { store, persistor };
