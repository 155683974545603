import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import AppLink from '../appLink';
import styles from './Loading.module.css';

/**
 * @param {Record<string, any>} props
 */
const Loading: React.FC = (props: any) => {
  const {
    errorText = '加载过程中出现错误，请稍后再试',
    timedOutText = '加载超时，请稍后再试',
    error,
    timedOut,
    pastDelay,
    retry,
  } = props;

  const handleRetry = useCallback(() => {
    if (timedOut) {
      retry();
    }

    if (error) {
      window.location.reload();
    }
  }, [timedOut, error, retry]);

  if (error || timedOut) {
    return (
      <div className={styles.message}>
        <div>{error ? errorText : timedOutText}</div>
        <div>
          {/* @ts-ignore */}
          <AppLink onClick={retry && handleRetry}>立即重试</AppLink>
        </div>
      </div>
    );
  }

  if (pastDelay) {
    const indicator = <LoadingOutlined className={styles.indicator} />;

    return <Spin indicator={indicator} />;
  }

  return null;
};

if (process.env.NODE_ENV !== 'production') {
  /** @type {any} */
  Loading.propTypes = {
    errorText: PropTypes.node,
    timedOutText: PropTypes.node,
    // eslint-disable-next-line react/forbid-prop-types
    error: PropTypes.object,
    timedOut: PropTypes.bool,
    pastDelay: PropTypes.bool,
    retry: PropTypes.func,
  };
}

export default Loading;
