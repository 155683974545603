import { Middleware } from 'redux';

export const actionLog: Middleware = (store) => (next) => (action) => {
  if (process.env.NODE_ENV !== 'production') {
    console.log('state 当前', store.getState());
    console.log('fire action ', action);
  }
  next(action);
  if (process.env.NODE_ENV !== 'production') {
    console.log('state 更新', store.getState());
  }
};
