import { createSlice } from '@reduxjs/toolkit';
import { mainRouters } from '../../route';

interface MenuState {
  subMenuKey: string;
  menuItemKey: string;
}

const initialState: MenuState = {
  subMenuKey: mainRouters[0].path,
  menuItemKey: mainRouters[0].children[0].path,
};

export const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    changeMenu: (state, action) => {
      state.subMenuKey = action.payload.subMenuKey;
      state.menuItemKey = action.payload.menuItemKey;
    },
  },
});
