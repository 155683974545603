import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';

interface DataManageState {
  loading: boolean;
  error: string | null;
  currentOrder: any;
}

const initialState: DataManageState = {
  loading: false,
  error: null,
  currentOrder: null,
};

export const getData = createAsyncThunk('dataManage/getData', async (token: string, thunkAPI) => {
  return Promise.resolve(1);
});

export const dataManageSlice = createSlice({
  name: 'dataManage',
  initialState,
  reducers: {},
  extraReducers: {
    [getData.pending.type]: (state) => {
      state.loading = true;
    },
    [getData.fulfilled.type]: (state, action) => {
      state.currentOrder = action.payload;
      state.loading = false;
      state.error = null;
    },
    [getData.rejected.type]: (state, action: PayloadAction<string | null>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});
