import React from 'react';
import { DatabaseOutlined, TagOutlined, CheckCircleOutlined } from '@ant-design/icons';
import Loadable from 'react-loadable';
import Loading from '../components/loading';

/* 数据校验--数据质检 */
const DataTest = Loadable({
  loader: () => import('./../pages/dataCheck/DataTest'),
  loading: Loading,
});

/* 数据管理--数据上传 */
const DataUpload = Loadable({
  loader: () => import('./../pages/dataManage/DataUpload'),
  loading: Loading,
});

/* 数据管理--数据导出 */
const QueryData = Loadable({
  loader: () => import('./../pages/dataManage/QueryData'),
  loading: Loading,
});

/* 标签体系--体系查询 */
const SystemQuery = Loadable({
  loader: () => import('./../pages/labelSystem/SystemQuery'),
  loading: Loading,
});

/* 标签体系--体系上传 */
const SystemUpload = Loadable({
  loader: () => import('./../pages/labelSystem/SystemUpload'),
  loading: Loading,
});

export const mainRouters = [
  {
    path: 'dataManage',
    name: '数据管理',
    icon: <DatabaseOutlined />,
    redirect: 'dataManage/queryData',
    children: [
      {
        path: 'queryData',
        name: '数据导出',
        component: QueryData,
      },
      {
        path: 'dataUpload',
        name: '数据上传',
        component: DataUpload,
      },
    ],
  },
  {
    path: 'labelSystem',
    name: '标签体系',
    icon: <TagOutlined />,
    redirect: 'labelSystem/systemQuery',
    children: [
      {
        path: 'systemQuery',
        name: '体系查询',
        component: SystemQuery,
      },
      {
        path: 'systemUpload',
        name: '体系上传',
        component: SystemUpload,
      },
    ],
  },
  {
    path: 'dataCheck',
    name: '数据校验',
    icon: <CheckCircleOutlined />,
    redirect: 'dataCheck/dataTest',
    children: [
      {
        path: 'dataTest',
        name: '数据质检',
        component: DataTest,
      },
    ],
  },
];
